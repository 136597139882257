import { useMemo, useState } from 'react'
import { useAsyncFn } from 'react-use'

type DictionaryDefs = [
  {
    word: string
    meanings: [
      {
        partOfSpeech: string
        definitions: [
          {
            definition: string
          }
        ]
      }
    ]
  }
]

export const Hint: React.FC<{ solution: string }> = ({ solution }) => {
  const [hintsUsed, setHintsUsed] = useState(0)
  const [state, getSolutionHint] = useAsyncFn(async () => {
    const response = await fetch(
      `https://api.dictionaryapi.dev/api/v2/entries/en/${solution}`
    )
    return (await response.json()) as DictionaryDefs
  }, [solution])
  const definitions = useMemo(() => {
    if (state.value) {
      return state.value.flatMap((x) =>
        x.meanings.flatMap(
          ({ partOfSpeech, definitions: [{ definition }] }) => ({
            partOfSpeech,
            def: definition,
          })
        )
      )
    }
    return []
  }, [state.value])

  return (
    <div className="mx-auto w-96 py-4 text-center">
      {state.loading ? null : state.error ? (
        <div>Error: {state.error.message}</div>
      ) : (
        <>
          <div className="text-left">
            {definitions.slice(0, hintsUsed).map((x) => (
              <p className="m-4 rounded-md bg-orange-300 p-2">
                {x.def} (
                <b>
                  <i>{x.partOfSpeech}</i>
                </b>
                )
              </p>
            ))}
          </div>
          {hintsUsed < definitions.length ? (
            <button
              className="m-4 rounded-md bg-blue-300 py-2 px-4"
              onClick={() => setHintsUsed((x) => x + 1)}
            >
              More
            </button>
          ) : null}
        </>
      )}
      {!state.error && !state.value ? (
        <button
          className="m-4 rounded-md bg-blue-300 py-2 px-4"
          onClick={() => {
            getSolutionHint()
            setHintsUsed(1)
          }}
        >
          {state.loading ? 'Loading' : 'Show hint'}
        </button>
      ) : null}
    </div>
  )
}
